import React from "react";
import { BaseLayout, OuterContainer } from "../components/Layout";
import { ServicesSection } from "../components/Sections/";
import { ContactForm } from "../components/Common";
import servicesData from "../data/servicesData";

const services = () => {
  return (
    <BaseLayout page="services" leftBox="faq" rightBox="contact">
      <ServicesSection servicesData={servicesData} />
      <OuterContainer reversed>
        <ContactForm />
      </OuterContainer>
    </BaseLayout>
  );
};

export default services;
