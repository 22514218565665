const servicesData = [
  {
    sectionName: "We Clear",
    sectionBackgroundColor: "bg-white",
    sectionTextColor: "text-green-950",
    text: [
      "Removal of rubbish to kerbside bins, or skip if needed.",
      "Help to sort through clutter and retain treasured possessions.",
      "Support to go through paperwork and keep essential documents.",
    ],
  },
  {
    sectionName: "We Clean",
    sectionBackgroundColor: "bg-blue-951",
    sectionTextColor: "text-white",
    text: [
      "One-off property cleans, including ‘home from hospital’ cleans.",
      "Cleaning as part of our decluttering service.",
      "Weekly, fortnightly and monthly cleans to suit the householder’s needs.",
    ],
  },
  {
    sectionName: "We Organise",
    sectionBackgroundColor: "bg-green-950",
    sectionTextColor: "text-white",
    text: [
      "Help to arrange storage for clothing, treasured possessions, paperwork and essential items in the home.",
      "Kitchens and bathrooms – sensible storage solutions to ensure everything has a place and is easy to use.",
    ],
  },
];

export default servicesData;
